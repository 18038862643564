import React from 'react'
import Navbar from "./global-components/navbar-v3";
import BannerV5 from "./section-components/banner-v5";
import Footer from "./global-components/footer";
import { Link } from "react-router-dom";

// import Text from "./section-components/Text";


const Philosophy = () => {

    return (
        <>
         <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
            <BannerV5
                headerTitle={"Philosophy Assignments"}
                  typeWriterValue={[
                    "Put a stop to deadline pressure, and have your homework done  ",
                    "by an expert.",
                    // "ASSIGNMENT",
                    // "PROJECT REPORT",
                  ]}
                smallText={
                    ""
                }
            />
             
            <div className="container mt-100 mb-100">
            <div className="row ltn__custom-gutter--- justify-content-center go-top mb-50">
       <div className="col-lg-3 col-sm-6 col-12" >
                  <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                      <img
                        src={"assets/img/icons/icon-img/22.png"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">182 300+</Link>
                        </h3>
                      <h6>
                        <Link to="#">DELIVERED ORDERS</Link>
                      </h6>
                      {/* <p>{data.discription}</p>
                      <Link className="ltn__service-btn" to="/contact">
                        Order now <i className="flaticon-right-arrow" />
                      </Link> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12" >
                  <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                      <img
                        src={"assets/img/icons/icon-img/22.png"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">5844 +</Link>
                        </h3>
                      <h6>
                        <Link to="#">EXPERTS</Link>
                      </h6>
                      {/* <p>{data.discription}</p>
                      <Link className="ltn__service-btn" to="/contact">
                        Order now <i className="flaticon-right-arrow" />
                      </Link> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12" >
                  <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                      <img
                        src={"assets/img/icons/icon-img/22.png"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">4.9/5</Link>
                        </h3>
                      <h6>
                        <Link to="#">CLIENT RATING</Link>
                      </h6>
                      {/* <p>{data.discription}</p>
                      <Link className="ltn__service-btn" to="/contact">
                        Order now <i className="flaticon-right-arrow" />
                      </Link> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12" >
                  <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                      <img
                        src={"assets/img/icons/icon-img/22.png"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">5+ Countries</Link>
                        </h3>
                      <h6>
                        <Link to="#">Operated in 5+ Countries</Link>
                      </h6>
                      {/* <p>{data.discription}</p>
                      <Link className="ltn__service-btn" to="/contact">
                        Order now <i className="flaticon-right-arrow" />
                      </Link> */}
                    </div>
                  </div>
                </div>
                </div>
                <h4>Why Should You Seek Help With Philosophy Assignment?</h4>
                <p>
                    Taking into account the complexity of this subject and the amount of time and effort required for completing philosophy assignments, there’s no wonder that lots of students are looking for help with their philosophy papers. Our professional writers have an in-depth understanding of five main branches of this field of study – ethics, logic, epistemology, aesthetics, and metaphysics, allowing them to tackle the most elaborated philosophy assignments. The experts at Assignments Help know exactly what is needed to create a high-quality philosophy paper and guarantee you the highest grades. So, don’t hesitate and give us a call – you’re on the right track!
                </p>
                <h4>Your Philosophy Assignment Help Is Right Here!Assignments Help</h4>
                <p>
                    Contact us today and view a sample of the kind of philosophy assignments we produce. It’s probably the best proof of how good our custom papers are. We’re confident in the content we create because we understand what your teachers are looking for. They want you to prove that you understand a concept on a deep level. More often than not, people get stuck in the same way of thinking. Philosophy is all about breaking out of the box. Our writers know how to create content that your teacher will appreciate. Check out why you should get philosophy homework help here and not elsewhere.
                </p>

                <h4>What’s Your Philosophy Assignment?</h4>
                <p>
                    When you let us know you need help with a philosophy assignment, we’ll tell you how long it will take to complete it. The content we create is readable and highly engaging. Reach out to us and see for yourself. Once a draft is complete, it is given to one of our experts who will further elaborate on the ideas. The paper they will create for you is one of exquisite quality, one that will exceed your teacher’s expectations. We can cover any topic, such as the meaning of life, or the purpose of deep thinking. What we offer you includes: Giving you philosophy Assignments Help by creating an amazing outline Double checking our submission for common grammar mistakes before sending it to you Verifying that our content meets your requirements and a free 10-day revision period for completed projects.
                </p>
                <h4>Why Select Assignment Help For Philosophy Assignment Help?</h4>
                <p>
                    Many students say “I need my biology lab done”. Homework assignments involving any type of science will take time and precision to complete correctly. Now that you know what services we offer, you need to consider the true benefits of enlisting assignment help. Perhaps the biggest advantage is that you’ll get to turn in your work on time. Being prompt with assignment submissions is a critical part in excelling in class. It shows your instructor that you care. If you turn to our experts, you are guaranteed to have your assignment done on time. Whether you need a paper written, or a longer project, we have the skills it takes to provide both quality and a fast turnaround. So you can rest assured that you will do well on your work.

                    There are so many topics to study in biology classes. Sometimes it can seem overwhelming to learn so much in such a short period of time. You have to study hard and focus on work from your other classes as well. That’s why it’s so easy to get caught up and miss deadlines or even turn in poor quality work. With biology help online, you won’t have to worry about any of these things. Some instructors don’t even accept late assignments, so the sooner you get yours done, the better. That’s a good enough reason to give our Assignments Help services a try and get the best homework help. You will be glad that you did.
                </p>
            </div>

            {/* <CallToActionV1 /> */}
            <Footer />
        </>
    )
}
export default Philosophy;